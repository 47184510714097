import * as React from 'react';
import styled from 'styled-components';
import PageLoading from '~/assets/images/common/goodrich_loading.png';

export interface IProps {
  width?: any;
  height?: any;
  type: string;
}

const LoadingBar: React.FC<IProps> = ({ width, height, type }) => {
  return (
    <>
      {type === 'type1' && (
        <StyledLoadingWrap>
          <StyledLoadingBarMid>
            <img src={PageLoading} width={220} alt={''} />
          </StyledLoadingBarMid>
        </StyledLoadingWrap>
      )}
      {type === 'type2' && (
        <StyledLoadingWrap>
          <StyledLoadingBarMid>로딩2</StyledLoadingBarMid>
        </StyledLoadingWrap>
      )}
    </>
  );
};

export default LoadingBar;

const StyledLoadingWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 99;
`;

const StyledLoadingBarMid = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
