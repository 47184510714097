import { createGlobalStyle } from 'styled-components';

export const GlobalFonts = createGlobalStyle`
    @font-face {
    font-family: Pretendard;
    font-weight: 700;
    src:url(${require('../assets/fonts/Pretendard-Bold.woff2')}) format('woff2'),
        url(${require('../assets/fonts/Pretendard-Bold.woff')}) format('woff'),
  }
  @font-face {
    font-family: Pretendard;
    font-weight: 600;
    src:url(${require('../assets/fonts/Pretendard-SemiBold.woff2')}) format('woff2'),
    url(${require('../assets/fonts/Pretendard-SemiBold.woff')}) format('woff'),
  }
  @font-face {
    font-family: Pretendard;
    font-weight: 500;
      src:url(${require('../assets/fonts/Pretendard-Medium.woff2')}) format('woff2'),
          url(${require('../assets/fonts/Pretendard-Medium.woff')}) format('woff'),
  }
  @font-face {
    font-family: Pretendard;
    font-weight: 400;
    src:url(${require('../assets/fonts/Pretendard-Regular.woff2')}) format('woff2'),
        url(${require('../assets/fonts/Pretendard-Regular.woff')}) format('woff'),
  }
  @font-face {
    font-family: Pretendard;
    font-weight: 300;
    src:url(${require('../assets/fonts/Pretendard-Light.woff2')}) format('woff2'),
        url(${require('../assets/fonts/Pretendard-Light.woff')}) format('woff'),
  }
`;
