import { createGlobalStyle } from 'styled-components';
import { colors } from '~/constants/color';

export const GlobalStyle = createGlobalStyle`
    /* Global Styles */
    body,
    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    form,
    fieldset,
    legend,
    textarea,
    p,
    blockquote,
    th,
    td,
    input,
    select,
    textarea,
    button {
    margin: 0;
    padding: 0;
    }
    fieldset,
    img {
    border: 0 none;
    vertical-align: top;
    }
    dl,
    ul,
    ol,
    menu,
    li {
    list-style: none;
    }
    blockquote,
    q {
    quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
    content: '';
    content: none;
    }
    /* input,
    select,
    textarea,
    button {
    font-size: 100%;
    vertical-align: middle;
    } */
    button {
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
    }
    table {
    border-collapse: collapse;
    border-spacing: 0;
    }
    body {
    -webkit-text-size-adjust: none; /* 뷰표트 변환시 폰트크기 자동확대 방지 */
    -webkit-overflow-scrolling: touch; /* 아이폰 스크롤  */
    }

    input[type='text'],
    input[type='password'],
    input[type='submit'],
    input[type='search'] {
    -webkit-appearance: none;
    }
    // todo
    input:checked[type='checkbox'] {
    background-color: #000;
    -webkit-appearance: checkbox;
    }
    button,
    input[type='button'],
    input[type='submit'],
    input[type='reset'],
    input[type='file'] {
    -webkit-appearance: button;
    border-radius: 0;
    }
    input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    }
    body {
    background: #fff;
    background-size: 1280px 480px;
    }
    body,
    th,
    td,
    input,
    select,
    textarea,
    button {
    font-family: Pretendard, Apple SD Gothic Neo,Malgun Gothic,맑은 고딕,sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.gray_1};
    }
    a {
    color: ${colors.gray_1};
    text-decoration: none;
    }
    a:active,
    a:hover {
    text-decoration: none;
    }
    address,
    caption,
    cite,
    code,
    dfn,
    em,
    var {
    font-style: normal;
    font-weight: normal;
    }

    caption {
    overflow: hidden;
    width: 100%;
    height: 1px;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
    }

    * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    :focus {
    outline: 0;
    }
    
    input[type='checkbox'],
    input[type='radio'] {overflow:hidden; position:absolute; left:-9999em; width:0; height:0; margin:0; padding:0; text-indent:-9999em; z-index:-1;}

    .swiper-pagination-bullet {
      width: 6px !important;
      height: 6px !important;
      border-radius: 50px !important;
      opacity: 1 !important;
      background: #fff !important;
    }
    .swiper-pagination-bullet-active {
      width: 20px !important;
      background: ${colors.primary_Y} !important;
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 18px !important;
    }
`;
