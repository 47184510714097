const deviceSize = {
  mobile: '375px',
  tablets: '768px',
  desktops: '1200px',
};

const device = {
  mobile: `(max-width:${deviceSize.mobile})`,
  tablets: `(max-width:${deviceSize.tablets})`,
  desktops: `(max-width:${deviceSize.desktops})`,
};

const theme = {
  device,
};

export default theme;
