import React from 'react';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingBar from '~/components/molecules/LoadingBar';
import ScrollToTop from '~/utils/ScrollTop';

// 페이지 추가
const Main = lazy(() => import('~/components/pages/main/Main'));
// 기업소개
const CompanyStory = lazy(() => import('~/components/pages/company/Story'));
const CompanyIdentity = lazy(() => import('~/components/pages/company/Identity'));
const CompanyHistory = lazy(() => import('~/components/pages/company/History'));
const CompanyPartners = lazy(() => import('~/components/pages/company/Partners'));
// 브랜드
const BrandGoodrichapp = lazy(() => import('~/components/pages/brand/Goodrichapp'));
const BrandGoodrichlounge = lazy(() => import('~/components/pages/brand/Goodrichlounge'));
const BrandGoodrichmall = lazy(() => import('~/components/pages/brand/Goodrichmall'));
const BrandGoodrichplanner = lazy(() => import('~/components/pages/brand/Goodrichplanner'));
const BrandRichmanager = lazy(() => import('~/components/pages/brand/Richmanager'));
const BrandRichplanner = lazy(() => import('~/components/pages/brand/Richplanner'));
const BrandGoodrichbiz = lazy(() => import('~/components/pages/brand/Goodrichbiz'));
// 홍보센터
const PrcenterNews = lazy(() => import('~/components/pages/prcenter/News'));
const PrcenterNewsDetail = lazy(() => import('~/components/pages/prcenter/NewsDetail'));
const PrcenterCorporateDisclosure = lazy(() => import('~/components/pages/prcenter/CorporateDisclosure'));
// 인재 채용
const RecruitPerson = lazy(() => import('~/components/pages/recruit/Person'));
const RecruitRichmanager = lazy(() => import('~/components/pages/recruit/Richmanager'));
const RecruitRichplanner = lazy(() => import('~/components/pages/recruit/Richplanner'));
const RecruitRichplannerbiz = lazy(() => import('~/components/pages/recruit/Richplannerbiz'));
// 고객지원
const CscenterBranch = lazy(() => import('~/components/pages/cscenter/Branch'));
const CscenterSearchplanner = lazy(() => import('~/components/pages/cscenter/Searchplanner'));
const CscenterSupport = lazy(() => import('~/components/pages/cscenter/Support'));
const CscenterLocation = lazy(() => import('~/components/pages/cscenter/Location'));
const CscenterRejectContact = lazy(() => import('~/components/pages/cscenter/RejectContact'));
const CscenterCertificate = lazy(() => import('~/components/pages/cscenter/Certificate'));
// 약관
const PolicyPrivacy = lazy(() => import('~/components/pages/policy/Privacy'));
const PolicyService = lazy(() => import('~/components/pages/policy/Service'));
const PolicyInsuranceAgencyCertificate = lazy(() => import('~/components/pages/policy/InsuranceAgencyCertificate'));
const PolicyConsumerProtection = lazy(() => import('~/components/pages/policy/ConsumerProtection'));
const PolicyInternalControl = lazy(() => import('~/components/pages/policy/InternalControl'));
// 약관 히스토리
const PrivacyVer24 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer24'));
const PrivacyVer23 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer23'));
const PrivacyVer22 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer22'));
const PrivacyVer21 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer21'));
const PrivacyVer20 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer20'));
const PrivacyVer19_1 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer19_1'));
const PrivacyVer19 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer19'));
const PrivacyVer18 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer18'));
const PrivacyVer17 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer17'));
const PrivacyVer16 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer16'));
const PrivacyVer15 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer15'));
const PrivacyVer14 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer14'));
const PrivacyVer13 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer13'));
const PrivacyVer12 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer12'));
const PrivacyVer11 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer11'));
const PrivacyVer10 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer10'));
const PrivacyVer9 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer9'));
const PrivacyVer8 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer8'));
const PrivacyVer7 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer7'));
const PrivacyVer6 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer6'));
const PrivacyVer5 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer5'));
const PrivacyVer4 = lazy(() => import('~/components/pages/policy/privacyHistory/PrivacyVer4'));

// Not Found
const NotFound = lazy(() => import('~/components/pages/notFound/NotFound'));

// Custom fallback component
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const Router = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // Optional: Reset any state that caused the error boundary to trigger
        }}
      >
        <Suspense fallback={<LoadingBar type="type1" />}>
          <ScrollToTop />
          <Routes>
            {/* 기업소개 */}
            <Route path="/main" element={<Main />} />
            <Route path="/company/story" element={<CompanyStory />} />
            <Route path="/company/identity" element={<CompanyIdentity />} />
            <Route path="/company/history" element={<CompanyHistory />} />
            <Route path="/company/partners" element={<CompanyPartners />} />
            {/*  브랜드 */}
            <Route path="/brand/goodrichapp" element={<BrandGoodrichapp />} />
            <Route path="/brand/goodrichlounge" element={<BrandGoodrichlounge />} />
            <Route path="/brand/goodrichmall" element={<BrandGoodrichmall />} />
            <Route path="/brand/goodrichplanner" element={<BrandGoodrichplanner />} />
            <Route path="/brand/richmanager" element={<BrandRichmanager />} />
            <Route path="/brand/richplanner" element={<BrandRichplanner />} />
            <Route path="/brand/goodrichbiz" element={<BrandGoodrichbiz />} />
            {/* 홍보센터 */}
            <Route path="/prcenter/news" element={<PrcenterNews />} />
            <Route path="/prcenter/news/:newsSeq" element={<PrcenterNewsDetail />} />
            <Route path="/prcenter/corporateDisclosure" element={<PrcenterCorporateDisclosure />} />
            {/* 인재채용 */}
            <Route path="/recruit/person" element={<RecruitPerson />} />
            <Route path="/recruit/richmanager" element={<RecruitRichmanager />} />
            <Route path="/recruit/richplanner" element={<RecruitRichplanner />} />
            <Route path="/recruit/richplannerbiz" element={<RecruitRichplannerbiz />} />
            {/* 고객지원 */}
            <Route path="/cscenter/branch" element={<CscenterBranch />} />
            <Route path="/cscenter/searchplanner" element={<CscenterSearchplanner />} />
            <Route path="/cscenter/support" element={<CscenterSupport />} />
            <Route path="/cscenter/location" element={<CscenterLocation />} />
            <Route path="/cscenter/rejectContact" element={<CscenterRejectContact />} />
            <Route path="/cscenter/certificate" element={<CscenterCertificate />} />
            {/* 약관 */}
            <Route path="/policy/privacy" element={<PolicyPrivacy />} />
            <Route path="/policy/service" element={<PolicyService />} />
            <Route path="/policy/insuranceAgencyCertificate" element={<PolicyInsuranceAgencyCertificate />} />
            <Route path="/policy/consumerProtection" element={<PolicyConsumerProtection />} />
            <Route path="/policy/internalControl" element={<PolicyInternalControl />} />
            {/*개인정보처리방침 히스토리*/}
            <Route path="/policy/privacyHistory/privacyVer24" element={<PrivacyVer24 />} />
            <Route path="/policy/privacyHistory/privacyVer23" element={<PrivacyVer23 />} />
            <Route path="/policy/privacyHistory/privacyVer22" element={<PrivacyVer22 />} />
            <Route path="/policy/privacyHistory/privacyVer21" element={<PrivacyVer21 />} />
            <Route path="/policy/privacyHistory/privacyVer20" element={<PrivacyVer20 />} />
            <Route path="/policy/privacyHistory/PrivacyVer19_1" element={<PrivacyVer19_1 />} />
            <Route path="/policy/privacyHistory/PrivacyVer19" element={<PrivacyVer19 />} />
            <Route path="/policy/privacyHistory/privacyVer18" element={<PrivacyVer18 />} />
            <Route path="/policy/privacyHistory/PrivacyVer17" element={<PrivacyVer17 />} />
            <Route path="/policy/privacyHistory/privacyVer16" element={<PrivacyVer16 />} />
            <Route path="/policy/privacyHistory/privacyVer15" element={<PrivacyVer15 />} />
            <Route path="/policy/privacyHistory/privacyVer14" element={<PrivacyVer14 />} />
            <Route path="/policy/privacyHistory/privacyVer13" element={<PrivacyVer13 />} />
            <Route path="/policy/privacyHistory/privacyVer12" element={<PrivacyVer12 />} />
            <Route path="/policy/privacyHistory/privacyVer11" element={<PrivacyVer11 />} />
            <Route path="/policy/privacyHistory/privacyVer10" element={<PrivacyVer10 />} />
            <Route path="/policy/privacyHistory/privacyVer9" element={<PrivacyVer9 />} />
            <Route path="/policy/privacyHistory/privacyVer8" element={<PrivacyVer8 />} />
            <Route path="/policy/privacyHistory/privacyVer7" element={<PrivacyVer7 />} />
            <Route path="/policy/privacyHistory/privacyVer6" element={<PrivacyVer6 />} />
            <Route path="/policy/privacyHistory/privacyVer5" element={<PrivacyVer5 />} />
            <Route path="/policy/privacyHistory/privacyVer4" element={<PrivacyVer4 />} />

            <Route path="/notfound" element={<NotFound />} />
            {/* Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Router;
