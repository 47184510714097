import React from 'react';
import Router from './router/Router';
import { GlobalStyle } from './styles/globalStyle';
import { GlobalFonts } from './styles/fonts';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
// import MetaTag from '~/utils/MetaTag';

const App: React.FC = () => {
  return (
    <>
      {/*<MetaTag*/}
      {/*  title="굿리치㈜"*/}
      {/*  description="굿리치㈜ Enriching Life 삶을 풍요롭게"*/}
      {/*  keywords="굿리치, GoodRich, 보험대리점, GA, 굿리치라운지, 굿리치몰, 굿리치플래너, 보험분석, 보험관리, 보험비교, 재무설계, 재무컨설팅, 굿리치채용, 리크루팅, 설계사채용, 리치매니저, 리치플래너"*/}
      {/*  url="https://goodrich.co.kr/main"*/}
      {/*  image="https://goodrich.co.kr/resources/images/goodrich/common/og_img.png"*/}
      {/*/>*/}
      <GlobalStyle />
      <GlobalFonts />
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </>
  );
};

export default App;
