export const colors = {
  //   Grayscale
  gray_1: '#1E1E1E',
  gray_2: '#999999',
  gray_3: '#DDDDDD',
  gray_4: '#F5F5F5',
  primary_G: '#737373',
  primary_G1: '#4F4F4F',

  // Primary Color
  primary_Y1: '#FF8A00',
  primary_Y: '#FF9B00',
  yellow_1: '#FFF3E0',
  red_1: '#FF5361',
};
